import { Controller } from "@hotwired/stimulus";
import axios from "axios";

export default class extends Controller {
  static values = { page: Number }
  static targets = ["cell", "input", "alertErrorTarget","reviewBlock", "viewMoreBtn", "likeBtn"];

  connect() {
    this.pageValue = 1;
    this.countLikes = 0;
    this.checkLikesStatus()
    const saveButton = this.element.querySelector(".save-btn");
    if (saveButton) {
      saveButton.disabled = true;
    }
    this.isDoubleClick = true;
  }

  edit(event) {
    const cell = event.target;
    const originalValue = cell.getAttribute("data-old-value");
    const originalValueInput = cell.innerText.trim();
    const field = cell.getAttribute("data-field");

    if (cell.querySelector("input")) return;

    const input = document.createElement("input");
    input.type = field === "rating" || field === "count-like" ? "number" : "text";
    input.value = field === "rating" || field === "count-like" ? cell.getAttribute("data-value") : originalValueInput;
    input.className = "form-control";
    input.style.width = "100%";

    if (field === "rating") {
      input.max = "5";
      input.min = "1";
    }

    if(field === "count-like"){
      input.min = "0";
    }

    cell.innerHTML = "";
    cell.appendChild(input);
    input.focus();

    input.addEventListener("blur", this.saveChanges.bind(this, cell, originalValue, field));
    input.addEventListener("keydown", this.handleKeydown.bind(this, input));
  }

  saveChanges(cell, originalValue, field, event) {
    if (this.isDoubleClick) {
      this.isDoubleClick = false;
      return;
    }

    const newValue = event.target.value.trim();

    const originalStrValue = String(originalValue);
    const newStrValue = String(newValue);

    setTimeout(() => {
      if (newStrValue !== originalStrValue) {
        cell.innerHTML = field === "rating" ? this.renderStars(newStrValue) : newStrValue;
        if (field === "rating") {
          cell.setAttribute("data-value", newStrValue);
        }
      } else {
        cell.innerHTML = field === "rating" ? this.renderStars(originalStrValue) : originalStrValue;
        if (field === "rating") {
          const originalValueRating = cell.getAttribute("data-old-value");
          cell.setAttribute("data-value", originalValueRating);
        }
      }
      this.isDoubleClick = true;

      let tr = cell.closest("tr");
      let titleElement = tr.querySelector("[data-field=\"title\"]");
      let contentElement = tr.querySelector("[data-field=\"content\"]");
      let ratingElement = tr.querySelector("[data-field=\"rating\"]");
      let authorElement = tr.querySelector("[data-field=\"author\"]");
      let jobElement = tr.querySelector("[data-field=\"job-title\"]");
      let likesElement = tr.querySelector("[data-field=\"count-like\"]");
      let createdAtElement = tr.querySelector("[data-field=\"created-at\"]");

      let title = titleElement.innerText.trim();
      let content = contentElement.innerText.trim();
      let rating = ratingElement.getAttribute("data-value");
      let author = authorElement.innerText.trim();
      let job = jobElement.innerText.trim();
      let likes = likesElement.innerText.trim();
      let createdAt = createdAtElement.innerText.trim();

      let titleOld = titleElement.getAttribute("data-old-value");
      let contentOld = contentElement.getAttribute("data-old-value");
      let ratingOld = ratingElement.getAttribute("data-old-value");
      let authorOld = authorElement.getAttribute("data-old-value");
      let jobOld = jobElement.getAttribute("data-old-value")
      let likesOld = likesElement.getAttribute("data-old-value")
      let createdAtOld = createdAtElement.getAttribute("data-old-value")

      if (
        title === titleOld
        && content === contentOld
        && rating === ratingOld
        && author === authorOld
        && job === jobOld
        && likes === likesOld
        && createdAt === createdAtOld
      ) {
        this.disableSaveButton(tr);
      } else {
        this.enableSaveButton(tr);
      }

    }, 0);
  }

  enableSaveButton(row) {
    const saveButton = row.querySelector(".save-btn");
    saveButton.disabled = false;
  }

  disableSaveButton(row) {
    const saveButton = row.querySelector(".save-btn");
    saveButton.disabled = true;
  }

  handleKeydown(input, event) {
    if (event.key === "Enter") {
      input.blur();
    }
  }

  renderStars(rating) {
    const filledStars = "★".repeat(rating);
    const emptyStars = "☆".repeat(5 - rating);
    return filledStars + emptyStars;
  }

  async save(e) {
    let button = e.target;
    let tr = button.closest("tr");
    let id = tr.getAttribute("data-id");
    let title = tr.querySelector("[data-field=\"title\"]").innerText.trim();
    let content = tr.querySelector("[data-field=\"content\"]").innerText.trim();
    let rating = tr.querySelector("[data-field=\"rating\"]").getAttribute("data-value");
    let author = tr.querySelector("[data-field=\"author\"]").innerText.trim();
    let job = tr.querySelector("[data-field=\"job-title\"]").innerText.trim();
    let likes = tr.querySelector("[data-field=\"count-like\"]").innerText.trim();
    let createdAt = tr.querySelector("[data-field=\"created-at\"]").innerText.trim();
    let locale = document.querySelector('[data-locale]').getAttribute('data-locale');

    const formData = new FormData();
    formData.append("title", title);
    formData.append("content", content);
    formData.append("rating", rating);
    formData.append("author", author);
    formData.append('locale', locale)
    formData.append('job', job)
    formData.append('likes', likes)
    formData.append('createdAt', createdAt)

    try {
      const { data } = await axios.post(`/admin/review/${id}/edit`, formData);
      this.showSuccessAlert("Success updated");
    } catch (e) {
      if (e.response.data) {
        this.showErrorAlert(e.response.data);
      }
    }
  }

  async delete(e) {
    let button = e.target;
    let tr = button.closest("tr");
    let id = tr.getAttribute("data-id");

    try {
      const { data } = await axios.delete(`/admin/review/${id}/delete`);
      location.reload();
    } catch (e) {
      if (e.response.data) {
        this.showErrorAlert(e.response.data);
      }
    }
  }

  showErrorAlert(message) {
    let errorElement = document.querySelector("#errorReview");
    errorElement.classList.remove("d-none");
    errorElement.innerHTML = message;
    setTimeout(() => {
      errorElement.classList.add("d-none");
      errorElement.innerHTML = "";
    }, 7000);
  }

  showSuccessAlert(message) {
    let successElement = document.querySelector("#successReview");
    successElement.classList.remove("d-none");
    successElement.innerHTML = message;
    setTimeout(() => {
      successElement.classList.add("d-none");
      successElement.innerHTML = "";
    }, 7000);
  }

  search(e) {
    let button = e.target;
    let div = button.closest("div");
    let input = div.querySelector("input");

    if (input.value) {
      let currentUrl = new URL(window.location);

      currentUrl.searchParams.set("q", input.value);

      window.location.href = currentUrl.toString();
    }
  }
  async fetchNextPage() {
    try {
      const url = `/ajax/review?page=${this.pageValue + 1}&limit=10`;
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`error: ${response.status}`);
      }
      const data = await response.json();

      if (data.reviews.length < 10) {
        this.hideLoadMoreButton();
      }
      const container = this.reviewBlockTarget;
      data.reviews.forEach(review => {
        const reviewHTML = `
              <div class="p-5 bg-white rounded-4 mb-4">
                    <div class="d-flex align-items-center mb-4">
                        <div class="me-3">
                            <img src="/static/pages/reviews/user.svg" height="54" width="54" alt="User Picture"
                                 loading="lazy">
                        </div>
                        <div>
                            <p class="fw-bold fs-6 mb-0">${review.author}</p>
                            <p class="text-gray fs-7 mb-0">${review.jobTitle}</p>

                        </div>
                    </div>

                    <div class="d-flex mb-3 align-items-center">
                       <img src="/static/pages/reviews/stars-${review.rating}.svg" loading="lazy" alt="stars">
                    <span class="ms-2 pt-1 review-stars">${review.rating}</span>
                        <span class="mx-3 text-gray mt-1">•</span>
                        <span class="text-gray mt-1">${review.createdAt.split(' ')[0]}</span>
                    </div>

                    <p class="fs-5 fw-bold mb-1">${review.title}</p>
                    <p class="mb-5">${review.content}</p>
                    <div>
                         <button class="bg-white border-0 px-0 py-1 d-flex align-items-center" data-review-target="likeBtn" data-action="review#postLike" data-review-id="${review.id }">
                        <svg class="like-icon me-2">
                            <use href="/static/pages/reviews/like-icons.svg#icon-nolike"></use>
                        </svg>
                      <span> ${review.countLike === 0 ? '' : review.countLike}</span>
                      </button>
                    </div>
                </div>             
                `;

        container.insertAdjacentHTML("beforeend", reviewHTML);
      });

      // this.pageValue += 1;
    } catch (error) {
      console.error("error", error);
    }
  }
  hideLoadMoreButton() {
    const button = this.viewMoreBtnTarget;
    if (button) {
      button.classList.add("d-none");
    }
  }
  checkLikesStatus(){
    this.likeBtnTargets.forEach(button => {
      const reviewId = button.getAttribute("data-review-id");
      const hasLiked = this.getCookie(`hasLiked_${reviewId}`);
      if (hasLiked === "true") {
        const use = button.querySelector("use");
        use.setAttribute("href", "/static/pages/reviews/like-icons.svg#icon-like");
      }
    });
  }

  async postLike(event) {
    const button = event.currentTarget;
    const reviewId = button.getAttribute("data-review-id");
    const cookieName = `hasLiked_${reviewId}`;
    const hasLiked = this.getCookie(cookieName);
    if (hasLiked === "true") {
      try {
        const url = `/ajax/review/${reviewId}/like/sub`;
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error(`error: ${response.status}`);
        }
        const data = await response.json();
        const span = button.querySelector("span");
        span.innerText = data.countLike;
      } catch (error) {
        console.error("error", error);
      }
      this.deleteCookie(cookieName);
      const use = button.querySelector("use");
      use.setAttribute("href", "/static/pages/reviews/like-icons.svg#icon-nolike");

    } else {
      try {
        const url = `/ajax/review/${reviewId}/like/add`;
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error(`error: ${response.status}`);
        }
        const data = await response.json();
        const span = button.querySelector("span");
        span.innerText = data.countLike;
      } catch (error) {
        console.error("error", error);
      }
      this.setCookie(cookieName, "true", 365);
      const use = button.querySelector("use");
      use.setAttribute("href", "/static/pages/reviews/like-icons.svg#icon-like");

    }
  }
  setCookie(name, value, days) {
    let expires = "";
    if (days) {
      let date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = "; expires=" + date.toUTCString();
    }
    const path = window.location.pathname;
    document.cookie = `${name}=${value || ""}${expires}; path=${path}; SameSite=Lax`;
  }

  getCookie(name) {
    let nameEQ = name + "=";
    let ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }
  deleteCookie(name) {
    const encodedName = encodeURIComponent(name);
    const domain = window.location.hostname;
    const paths = ['/', window.location.pathname];

    paths.forEach(path => {
      document.cookie = `${encodedName}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=${path}; domain=${domain};`;
      document.cookie = `${encodedName}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=${path};`;
    });
  }
}
