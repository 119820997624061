import { Controller } from "@hotwired/stimulus";
import axios from "axios";
import { validLinkPattern } from "../../../RegExpValidations";

export default class extends Controller {
  static targets = [
    "verifyBtn", "domainNameInput", "errorBlock", "errorMessage", "saveBtn", "successImage", "errorImage",
    "verifyFolderBtn", "folderDomainNameInput", "folderErrorBlock", "folderErrorMessage", "saveFolderBtn",
    "successFolderImage", "errorFolderImage",
    "errorValidDomain",
    "domainType",
  ];

  connect() {
    this.verifyDomainSendRequest();
    this.verifyFolderDomainSendRequest();
    this.typeOfTabs();
  }

  verifyDomain(event) {
    event.preventDefault();
    this.verifyDomainSendRequest();
  }

  domainInput() {
    this.errorValidDomainTarget.classList.add("d-none");
    this.errorImageTarget.style.display = "none";
    this.errorBlockTarget.style.display = "none";
    this.errorMessageTarget.innerHTML = "";
    this.errorMessageTarget.classList.add("d-none");
    this.saveBtnTarget.setAttribute("disabled", "");
    this.saveBtnTarget.classList.add("text-white", "btn-light-gray");
  }

  verifyDomainSendRequest() {
    this.verifySendRequest(
        'domainNameInput',
        'saveBtn',
        'errorBlock',
        'errorMessage',
        'successImage',
        'errorImage',
        'verifyBtn',
    );
  }

  verifyFolderDomain(event) {
    event.preventDefault();
    this.verifyFolderDomainSendRequest();
  }

  domainFolderInput() {
    this.errorValidDomainTarget.classList.add("d-none");
    this.errorFolderImageTarget.style.display = "none";
    this.folderErrorBlockTarget.style.display = "none";
    this.folderErrorMessageTarget.innerHTML = "";
    this.saveFolderBtnTarget.setAttribute("disabled", "");
    this.saveFolderBtnTarget.classList.add("text-white", "btn-light-gray");
  }

  verifyFolderDomainSendRequest() {
    this.verifySendRequest(
        'folderDomainNameInput',
        'saveFolderBtn',
        'folderErrorBlock',
        'folderErrorMessage',
        'successFolderImage',
        'errorFolderImage',
        'verifyFolderBtn',
    );
  }

  verifySendRequest(domainNameInput, saveBtn, errorBlock, errorMessage, successImage, errorImage, verifyBtn) {
    const domainPattern = RegExp(/^(?!https?:\/\/)([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/);
    const domainName = this[`${domainNameInput}Target`].value.trim();

    if (domainName) {
      if (!domainPattern.test(domainName)) {
        this.showValidation(successImage, errorImage, errorBlock, saveBtn);
        return;
      }

      let locate = document
          .querySelector("[data-locale]")
          .getAttribute("data-locale");

        const verifyBtnId = document.getElementById(verifyBtn);
        const domainType = verifyBtnId.getAttribute('data-domain-type');
        const entryId = verifyBtnId.getAttribute('data-entry-id') ?? '';

        axios.post(
          `/foreign-domain/verify-domain`,
          `domainName=${encodeURIComponent(domainName)}&_locale=${locate}&domainType=${domainType}&entryId=${entryId}`
      )
          .then(({data}) => {
            if (Array.isArray(data) && data[0] === "success") {
              this[`${successImage}Target`].classList.add('d-block');
              this[`${errorImage}Target`].classList.remove('d-block');
              this[`${errorBlock}Target`].style.display = "none";
              this[`${errorMessage}Target`].innerHTML = "";
              this[`${errorMessage}Target`].classList.add("d-none");
              this[`${saveBtn}Target`].removeAttribute("disabled");
              this[`${saveBtn}Target`].classList.remove("text-white", "btn-light-gray");
            } else {
              throw new Error();
            }
          })
          .catch((error) => {
            this[`${successImage}Target`].classList.remove("d-block");
            this[`${errorImage}Target`].classList.add("d-block");
            this[`${saveBtn}Target`].setAttribute("disabled", "");
            this[`${saveBtn}Target`].classList.add(
              "text-white",
              "btn-light-gray"
            );
            this[`${errorBlock}Target`].style.display = "inline-block";
            if (error.response.data.error) {
              this[`${errorMessage}Target`].innerHTML =
                error.response.data.error;
              this[`${errorMessage}Target`].classList.remove("d-none");
            }
            console.error(error);
          });
    }
  }

  showValidation(successImage, errorImage, errorBlock, saveBtn) {
    if (this.hasTarget(errorImage)) {
      this[`${errorImage}Target`].classList.add('d-block');
    }
    if (this.hasTarget(successImage)) {
      this[`${successImage}Target`].classList.remove('d-block');
    }
    this[`${errorBlock}Target`].style.display = "inline-block";
    this[`${saveBtn}Target`].setAttribute("disabled", "");
    this[`${saveBtn}Target`].classList.add("text-white", "btn-light-gray");
  }

  hasTarget(targetName) {
    return this.targets.find(targetName) !== undefined;
  }

  typeOfTabs(){
    document.addEventListener("DOMContentLoaded", function() {

      let urlParams = new URLSearchParams(window.location.search);
      let activeTab = urlParams.get('active_tab');


      if (activeTab === 'pills-profile-tab') {
        let profileTabButton = document.getElementById('pills-profile-tab');
        if (profileTabButton) {
          profileTabButton.click();
        }
      }
    });
  }
}
