import { Controller } from "@hotwired/stimulus";
import axios from "axios";

export default class extends Controller {
  connect() {}

  async generateDispute(e) {
    e.preventDefault();
    let generateDisputeBtn = e.target;
    this.disableLink(generateDisputeBtn);

    const userItemElement = generateDisputeBtn.closest(".search_user_item");
    const userId = userItemElement.getAttribute("data-user-id");
    const downloadDisputeBtn = userItemElement.querySelector(".dispute_download");

    try {
      await axios.get(`/generate-dispute?id=${userId}`);
      this.enableLink(downloadDisputeBtn);
    } catch (e) {
      const response = e.response;
      const statusCode = response.status;
      let errorMessage = 'Неизвестная ошибка генерации. ';

      let dataError = response.data?.error;
      if (typeof dataError !== 'undefined') {
        errorMessage = dataError;
      } else if (statusCode === 504 || statusCode === 524) {
        errorMessage =
          'Превышено время генерации диспута (диспут слишком большой). Подождите пока диспут ' +
          'сгенерируется (обновляйте страницу для проверки результата). Когда диспут сгенерируется, кнопка ' +
          'скачивания диспута станет активной. НЕ нажимайте кнопку генерации пока не появится кнопка скачивания.'
        ;
      }

      alert(errorMessage);
    }

    this.enableLink(generateDisputeBtn);
  }

  downloadDispute(e) {
    let downloadDisputeBtn = e.target;

    this.disableLink(downloadDisputeBtn);
  }

  disableLink(link) {
    link.style.pointerEvents = "none";  // Disable link
    link.style.opacity = "0.5";         // Make it look disabled
  }

  enableLink(link) {
    link.style.pointerEvents = "auto";  // Enable link interaction
    link.style.opacity = "1";           // Reset the opacity to normal
  }
}
